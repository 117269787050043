import React, { useState } from 'react';
import { Card, Radio } from 'antd';
import { DualAxes } from '@ant-design/plots';
import { ThunderboltOutlined, GlobalOutlined, PoundOutlined } from '@ant-design/icons';
import { COLORS } from '../../../../constants/colors';

const RecentEnergySavingsPanel = () => {
  const [timeRange, setTimeRange] = useState('year');

  const [data, setData] = useState([
    { period: '01/24', type: 'ARE', value: 48680 },
    { period: '01/24', type: 'TES', value: 29700 },
    { period: '01/24', type: 'Savings', value: 37 },
    { period: '01/24', type: 'CO2', value: 6465.27 },

    { period: '02/24', type: 'ARE', value: 46680 },
    { period: '02/24', type: 'TES', value: 29700 },
    { period: '02/24', type: 'Savings', value: 37 },
    { period: '02/24', type: 'CO2', value: 6465.27 },

    { period: '03/24', type: 'ARE', value: 44680 },
    { period: '03/24', type: 'TES', value: 29700 },
    { period: '03/24', type: 'Savings', value: 37 },
    { period: '03/24', type: 'CO2', value: 6465.27 },

    { period: 'Apr/2024', type: 'ARE', value: 47680 },
    { period: 'Apr/2024', type: 'TES', value: 29700 },
    { period: 'Apr/2024', type: 'Savings', value: 37 },
    { period: 'Apr/2024', type: 'CO2', value: 6465.27 },

    { period: '05/24', type: 'ARE', value: 46680 },
    { period: '05/24', type: 'TES', value: 29700 },
    { period: '05/24', type: 'Savings', value: 38 },
    { period: '05/24', type: 'CO2', value: 6465.27 },

    { period: '06/24', type: 'ARE', value: 48680 },
    { period: '06/24', type: 'TES', value: 29700 },
    { period: '06/24', type: 'Savings', value: 35 },
    { period: '06/24', type: 'CO2', value: 6465.27 },

    { period: '07/24', type: 'ARE', value: 46680 },
    { period: '07/24', type: 'TES', value: 29700 },
    { period: '07/24', type: 'Savings', value: 39 },
    { period: '07/24', type: 'CO2', value: 6465.27 },

    { period: '08/24', type: 'ARE', value: 44680 },
    { period: '08/24', type: 'TES', value: 29700 },
    { period: '08/24', type: 'Savings', value: 36 },
    { period: '08/24', type: 'CO2', value: 6465.27 },

    { period: '09/24', type: 'ARE', value: 46680 },
    { period: '09/24', type: 'TES', value: 29700 },
    { period: '09/24', type: 'Savings', value: 38 },
    { period: '09/24', type: 'CO2', value: 6465.27 },

    { period: '10/24', type: 'ARE', value: 47680 },
    { period: '10/24', type: 'TES', value: 29700 },
    { period: '10/24', type: 'Savings', value: 37 },
    { period: '10/24', type: 'CO2', value: 6465.27 },

    { period: '11/24', type: 'ARE', value: 48680 },
    { period: '11/24', type: 'TES', value: 29700 },
    { period: '11/24', type: 'Savings', value: 39 },
    { period: '11/24', type: 'CO2', value: 6765.27 },

    { period: '12/24', type: 'ARE', value: 43680 },
    { period: '12/24', type: 'TES', value: 29700 },
    { period: '12/24', type: 'Savings', value: 35 },
    { period: '12/24', type: 'CO2', value: 6365.27 },
  ]);

  const getFilteredData = () => {
    const currentDate = new Date();
    const months = {
      month: 1,
      quarter: 3,
      year: 12
    };
    
    const monthsToShow = months[timeRange];
    const filteredData = data.filter(item => {
      const [month, year] = item.period.split('/');
      const itemDate = new Date(2024, parseInt(month) - 1);
      const diffMonths = (currentDate.getFullYear() - itemDate.getFullYear()) * 12 + 
                        (currentDate.getMonth() - itemDate.getMonth());
      return diffMonths < monthsToShow;
    });
    return filteredData;
  };

  const filteredData = getFilteredData();
  const energyData = filteredData?.filter(item => item.type === 'ARE' || item.type === 'TES');
  const savingsData = filteredData?.filter(item => item.type === 'Savings');
  const co2Data = filteredData?.filter(item => item.type === 'CO2');
  const totalEnergy = energyData?.reduce((acc, item) => acc + item.value, 0);
  const [totalEnergySavings, setTotalEnergySavings] = useState(3456);
  const [costReduction, setCostReduction] = useState(79023);

  const config = {
    xField: 'period',
    legend: false,
    children: [
      {
        data: energyData,
        type: 'interval',
        colorField: 'type',
        yField: 'value',
        style: {
          maxWidth: '40',
          fill: ({ type }) => {
            if (type === 'ARE') return COLORS.chartGreen;
            return COLORS.chartBrown;
          },
        },
        axis: {
          x: {
            labelFill: 'white',
            labelFontSize: 16,
            labelFontWeight: 'bold',
          },
          y: {
            labelFill: 'white',
            labelFontSize: 16,
            labelFontWeight: 'bold',
          },
        },
        stack: {
          groupBy: ['x', 'period'],
          series: false,
        }
      },
      {
        type: 'line',
        shapeField: 'smooth',
        yField: 'value',
        data: savingsData,
        label: {
          text: 'value',
          textBaseline: 'bottom',
          position: 'inside',
          style: {
            fill: '#ffffff'
          }
        },
        axis: {
          y: {
            position: 'right',
            labelFill: 'white',
            labelFontSize: 16,
            labelFontWeight: 'bold',
          },
        },
        scale: {
          y: {
            domainMin: 0,
            domainMax: 100,
          },
        },
        style: {
          stroke: COLORS.primaryYellow,
          lineWidth: 8,
        },
      }
    ],
  };

  return (
    <Card 
      className="card-wrapper-dark" 
      style={{ height: '100%' }}>
      <div style={
        { 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: 'calc(100% - 50px)' 
        }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '24px', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '16px', width: '100%', justifyContent: 'space-evenly' }}>
            <div className='ant-typography' style={{ textAlign: 'center' }}>
              <h2 className='ant-typography'>
                <ThunderboltOutlined style={{marginRight: '8px'}} />
                Total Energy Used
              </h2>
              <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                {Math.round(totalEnergy/1000)} kWh (x1000)
              </div>
            </div>

            <div className='ant-typography' style={{ textAlign: 'center' }}>
              <h2 className='ant-typography'>
                <PoundOutlined style={{marginRight: '8px'}} />
                Total Savings
              </h2>
              <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
               £{costReduction.toLocaleString('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
              </div>
            </div>

            <div className='ant-typography' style={{ textAlign: 'center' }}>
              <h2 className='ant-typography'>
                <GlobalOutlined style={{marginRight: '8px'}} />
                Total CO2 Saved
              </h2>
              <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                16.45 tonnes
              </div>
            </div>
          </div>
          <div style={{ width: '100%', height: '400px' }}>
            <DualAxes {...config} />
          </div>
          <div>
            <span className='ant-typography' style={{ marginRight: '8px' }}>Set range:</span>
            <Radio.Group 
              value={timeRange} 
              onChange={(e) => setTimeRange(e.target.value)}
              style={{ marginBottom: '16px' }}
            >
              <Radio.Button value="month">Month</Radio.Button>
              <Radio.Button value="quarter">Quarter</Radio.Button>
              <Radio.Button value="year">Year</Radio.Button>
            </Radio.Group>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default RecentEnergySavingsPanel;
