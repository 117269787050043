import React from 'react';
import { Card } from 'antd';
import { Pie } from '@ant-design/plots';
import { COLORS } from '../../../../constants/colors';
const SavingsPanel = () => {
  // Example data - replace with real data
  const data = [
    { type: 'power', value: 37.4 },
    { type: '', value: 100-37.4 },
  ];
  
  const config = {
    data,
    legend: false,
    angleField: 'value',
    colorField: 'type',
    innerRadius: 0.6,
    label: false,
    style: {
      fill: ({ type }) => {
        if (type === '') return 'darkgrey';
        return COLORS.primaryYellow;
      },
    },
    annotations: [
      {
        type: 'text',
        style: {
          text: `Total\nSaving\n${data[0].value}%`,
          x: '50%',
          y: '50%',
          textAlign: 'center',
          fontSize: 26,
          fontStyle: 'bold',
          fill: '#ffffff'
        },
      },
    ],
  };

  return (
    <Card 
      className="card-wrapper-dark" 
      style={{ height: '100%' }}>
      <div style={
        { 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: 'calc(100% - 50px)' 
        }}>
        <div style={{ display: 'flex', width: '70%', gap: '24px', justifyContent: 'center', alignItems: 'center' }}>
          <Pie {...config} />
        </div>
      </div>
    </Card>
  );
};

export default SavingsPanel;